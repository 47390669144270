import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=632aa0fa"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsLanguageSwitcher: require('/var/app/components/Commons/LanguageSwitcher.vue').default,Theme0NavContainer: require('/var/app/components/Theme0/NavContainer.vue').default,CommonsNuxtImage: require('/var/app/components/Commons/NuxtImage.vue').default,Theme0Menubar: require('/var/app/components/Theme0/Menubar.vue').default,CommonsCopyButton: require('/var/app/components/Commons/CopyButton.vue').default})
